/*
	Alpha by HTML5 UP
	html5up.net | @ajlkn
	Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
*/

/* Reset */

/* Based on meyerweb.com/eric/tools/css/reset (v2.0 | 20110126 | License: public domain) */

	html, body, div, span, applet, object,
	iframe, h1, h2, h3, h4, h5, h6, p, blockquote,
	pre, a, abbr, acronym, address, big, cite,
	code, del, dfn, em, img, ins, kbd, q, s, samp,
	small, strike, strong, sub, sup, tt, var, b,
	u, i, center, dl, dt, dd, ol, ul, li, fieldset,
	form, label, legend, table, caption, tbody,
	tfoot, thead, tr, th, td, article, aside,
	canvas, details, embed, figure, figcaption,
	footer, header, hgroup, menu, nav, output, ruby,
	section, summary, time, mark, audio, video {
		margin: 0;
		padding: 0;
		border: 0;
		font-size: 100%;
		font: inherit;
		vertical-align: baseline;
	}

	article, aside, details, figcaption, figure,
	footer, header, hgroup, menu, nav, section {
		display: block;
	}

	body {
		line-height: 1;
	}

	ol, ul {
		list-style: none;
	}

	blockquote, q {
		quotes: none;
	}

		blockquote:before, blockquote:after, q:before, q:after {
			content: '';
			content: none;
		}

	table {
		border-collapse: collapse;
		border-spacing: 0;
	}

	body {
		-webkit-text-size-adjust: none;
	}

	mark {
		background-color: transparent;
		color: inherit;
	}

	input::-moz-focus-inner {
		border: 0;
		padding: 0;
	}

	input, select, textarea {
		-moz-appearance: none;
		-webkit-appearance: none;
		-ms-appearance: none;
		appearance: none;
	}
	
	header h1 {
		font-weight: bold;
	}
/* Basic */

	@-ms-viewport {
		width: device-width;
	}

	body {
		-ms-overflow-style: scrollbar;
	}

	@media screen and (max-width: 480px) {

		html, body {
			min-width: 320px;
		}

	}

	html {
		box-sizing: border-box;
	}

	*, *:before, *:after {
		box-sizing: inherit;
	}

	body {
		background: #f5f5f5;
	}

	body, input, select, textarea {
		color: #777;
		font-family: "Source Sans Pro", sans-serif;
		font-size: 16pt;
		font-weight: 300;
		line-height: 1.65em;
		letter-spacing: -0.015em;
	}

	a {
		-moz-transition: color 0.2s ease-in-out, border-bottom-color 0.2s ease-in-out;
		-webkit-transition: color 0.2s ease-in-out, border-bottom-color 0.2s ease-in-out;
		-ms-transition: color 0.2s ease-in-out, border-bottom-color 0.2s ease-in-out;
		transition: color 0.2s ease-in-out, border-bottom-color 0.2s ease-in-out;
		/*border-bottom: dotted 1px;*/
		color: #111111;
		text-decoration: none;
		font-weight: bold;
	}
		a:hover {
			border-bottom-color: transparent;
		}

	strong, b {
		color: #646464;
		font-weight: 400;
	}

	em, i {
		font-style: italic;
	}

	p {
		margin: 0 0 2em 0;
	}

	h1, h2, h4, h5, h6 {
		font-weight: 300;
		line-height: 1em;
		margin: 0 0 0.5em 0;
		color:#000000;
	}

		h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
			color: inherit;
			text-decoration: none;
		}

	h2 {
		font-size: 2.25em;
		line-height: 1.5em;
		letter-spacing: -0.035em;
		text-align:center;
		margin-top:65px;
		margin-bottom:40px;
	}

	h3 {
		font-size: 1.75em;
		line-height: 1.5em;
		letter-spacing: -0.025em;
		font-weight: bold;
		color: #646464;
		text-align: center;
		padding: 10px;
	}

	h4 {
		font-size: 1.1em;
		line-height: 1.5em;
		letter-spacing: 0;
	}

	h5 {
		font-size: 0.9em;
		line-height: 1.5em;
		letter-spacing: 0;
	}

	h6 {
		font-size: 0.7em;
		line-height: 1.5em;
		letter-spacing: 0;
	}

	sub {
		font-size: 0.8em;
		position: relative;
		top: 0.5em;
	}

	sup {
		font-size: 0.8em;
		position: relative;
		top: -0.5em;
	}

	hr {
		border: 0;
		border-bottom: solid 2px #e5e5e5;
		margin: 2em 0;
	}

		hr.major {
			margin: 3em 0;
		}

	blockquote {
		border-left: solid 4px #e5e5e5;
		font-style: italic;
		margin: 0 0 2em 0;
		padding: 0.5em 0 0.5em 2em;
	}

	pre {
		-webkit-overflow-scrolling: touch;
		background: #f8f8f8;
		border-radius: 6px;
		border: solid 1px #e5e5e5;
		font-family: monospace;
		font-size: 0.9em;
		line-height: 1.75em;
		margin: 0 0 2em 0;
		overflow-x: auto;
		padding: 1em 1.5em;
	}

	code {
		background: #f8f8f8;
		border-radius: 6px;
		border: solid 1px #e5e5e5;
		font-family: monospace;
		font-size: 0.9em;
		margin: 0 0.25em;
		padding: 0.25em 0.65em;
	}

	.align-left {
		text-align: left;
	}

	.align-center {
		text-align: center;
	}

	.align-right {
		text-align: right;
	}

/* Row */

	.row {
		display: flex;
		flex-wrap: wrap;
		box-sizing: border-box;
		align-items: stretch;
	}

		.row > * {
			box-sizing: border-box;
		}

		.row.gtr-uniform > * > :last-child {
			margin-bottom: 0;
		}

		.row.aln-left {
			justify-content: flex-start;
		}

		.row.aln-center {
			justify-content: center;
		}

		.row.aln-right {
			justify-content: flex-end;
		}

		.row.aln-top {
			align-items: flex-start;
		}

		.row.aln-middle {
			align-items: center;
		}

		.row.aln-bottom {
			align-items: flex-end;
		}

		.row > .imp {
			order: -1;
		}

		.row > .col-1 {
			width: 8.3333333333%;
		}

		.row > .off-1 {
			margin-left: 8.3333333333%;
		}

		.row > .col-2 {
			width: 16.6666666667%;
		}

		.row > .off-2 {
			margin-left: 16.6666666667%;
		}

		.row > .col-3 {
			width: 25%;
		}

		.row > .off-3 {
			margin-left: 25%;
		}

		.row > .col-4 {
			width: 33.3333333333%;
		}

		.row > .off-4 {
			margin-left: 33.3333333333%;
		}

		.row > .col-5 {
			width: 41.6666666667%;
		}

		.row > .off-5 {
			margin-left: 41.6666666667%;
		}

		.row > .col-6 {
			width: 50%;
		}

		.row > .off-6 {
			margin-left: 50%;
		}

		.row > .col-7 {
			width: 58.3333333333%;
		}

		.row > .off-7 {
			margin-left: 58.3333333333%;
		}

		.row > .col-8 {
			width: 66.6666666667%;
		}

		.row > .off-8 {
			margin-left: 66.6666666667%;
		}

		.row > .col-9 {
			width: 75%;
		}

		.row > .off-9 {
			margin-left: 75%;
		}

		.row > .col-10 {
			width: 83.3333333333%;
		}

		.row > .off-10 {
			margin-left: 83.3333333333%;
		}

		.row > .col-11 {
			width: 91.6666666667%;
		}

		.row > .off-11 {
			margin-left: 91.6666666667%;
		}

		.row > .col-12 {
			width: 100%;
		}

		.row > .off-12 {
			margin-left: 100%;
		}

		.row.gtr-0 {
			margin-top: 0;
			margin-left: 0em;
		}

			.row.gtr-0 > * {
				padding: 0 0 0 0em;
			}

			.row.gtr-0.gtr-uniform {
				margin-top: 0em;
			}

				.row.gtr-0.gtr-uniform > * {
					padding-top: 0em;
				}

		.row.gtr-25 {
			margin-top: 0;
			margin-left: -0.5em;
		}

			.row.gtr-25 > * {
				padding: 0 0 0 0.5em;
			}

			.row.gtr-25.gtr-uniform {
				margin-top: -0.5em;
			}

				.row.gtr-25.gtr-uniform > * {
					padding-top: 0.5em;
				}

		.row.gtr-50 {
			margin-top: 0;
			margin-left: -1em;
		}

			.row.gtr-50 > * {
				padding: 0 0 0 1em;
			}

			.row.gtr-50.gtr-uniform {
				margin-top: -1em;
			}

				.row.gtr-50.gtr-uniform > * {
					padding-top: 1em;
				}

		.row {
			margin-top: 0;
			margin-left: -2em;
		}

			.row > * {
				padding: 0 0 0 2em;
			}

			.row.gtr-uniform {
				margin-top: -2em;
			}

				.row.gtr-uniform > * {
					padding-top: 2em;
				}

		.row.gtr-150 {
			margin-top: 0;
			margin-left: -3em;
		}

			.row.gtr-150 > * {
				padding: 0 0 0 3em;
			}

			.row.gtr-150.gtr-uniform {
				margin-top: -3em;
			}

				.row.gtr-150.gtr-uniform > * {
					padding-top: 3em;
				}

		.row.gtr-200 {
			margin-top: 0;
			margin-left: -4em;
		}

			.row.gtr-200 > * {
				padding: 0 0 0 4em;
			}

			.row.gtr-200.gtr-uniform {
				margin-top: -4em;
			}

				.row.gtr-200.gtr-uniform > * {
					padding-top: 4em;
				}

		@media screen and (max-width: 1680px) {

			.row {
				display: flex;
				flex-wrap: wrap;
				box-sizing: border-box;
				align-items: stretch;
			}

				.row > * {
					box-sizing: border-box;
				}

				.row.gtr-uniform > * > :last-child {
					margin-bottom: 0;
				}

				.row.aln-left {
					justify-content: flex-start;
				}

				.row.aln-center {
					justify-content: center;
				}

				.row.aln-right {
					justify-content: flex-end;
				}

				.row.aln-top {
					align-items: flex-start;
				}

				.row.aln-middle {
					align-items: center;
				}

				.row.aln-bottom {
					align-items: flex-end;
				}

				.row > .imp-wide {
					order: -1;
				}

				.row > .col-1-wide {
					width: 8.3333333333%;
				}

				.row > .off-1-wide {
					margin-left: 8.3333333333%;
				}

				.row > .col-2-wide {
					width: 16.6666666667%;
				}

				.row > .off-2-wide {
					margin-left: 16.6666666667%;
				}

				.row > .col-3-wide {
					width: 25%;
				}

				.row > .off-3-wide {
					margin-left: 25%;
				}

				.row > .col-4-wide {
					width: 33.3333333333%;
				}

				.row > .off-4-wide {
					margin-left: 33.3333333333%;
				}

				.row > .col-5-wide {
					width: 41.6666666667%;
				}

				.row > .off-5-wide {
					margin-left: 41.6666666667%;
				}

				.row > .col-6-wide {
					width: 50%;
				}

				.row > .off-6-wide {
					margin-left: 50%;
				}

				.row > .col-7-wide {
					width: 58.3333333333%;
				}

				.row > .off-7-wide {
					margin-left: 58.3333333333%;
				}

				.row > .col-8-wide {
					width: 66.6666666667%;
				}

				.row > .off-8-wide {
					margin-left: 66.6666666667%;
				}

				.row > .col-9-wide {
					width: 75%;
				}

				.row > .off-9-wide {
					margin-left: 75%;
				}

				.row > .col-10-wide {
					width: 83.3333333333%;
				}

				.row > .off-10-wide {
					margin-left: 83.3333333333%;
				}

				.row > .col-11-wide {
					width: 91.6666666667%;
				}

				.row > .off-11-wide {
					margin-left: 91.6666666667%;
				}

				.row > .col-12-wide {
					width: 100%;
				}

				.row > .off-12-wide {
					margin-left: 100%;
				}

				.row.gtr-0 {
					margin-top: 0;
					margin-left: 0em;
				}

					.row.gtr-0 > * {
						padding: 0 0 0 0em;
					}

					.row.gtr-0.gtr-uniform {
						margin-top: 0em;
					}

						.row.gtr-0.gtr-uniform > * {
							padding-top: 0em;
						}

				.row.gtr-25 {
					margin-top: 0;
					margin-left: -0.5em;
				}

					.row.gtr-25 > * {
						padding: 0 0 0 0.5em;
					}

					.row.gtr-25.gtr-uniform {
						margin-top: -0.5em;
					}

						.row.gtr-25.gtr-uniform > * {
							padding-top: 0.5em;
						}

				.row.gtr-50 {
					margin-top: 0;
					margin-left: -1em;
				}

					.row.gtr-50 > * {
						padding: 0 0 0 1em;
					}

					.row.gtr-50.gtr-uniform {
						margin-top: -1em;
					}

						.row.gtr-50.gtr-uniform > * {
							padding-top: 1em;
						}

				.row {
					margin-top: 0;
					margin-left: -2em;
				}

					.row > * {
						padding: 0 0 0 2em;
					}

					.row.gtr-uniform {
						margin-top: -2em;
					}

						.row.gtr-uniform > * {
							padding-top: 2em;
						}

				.row.gtr-150 {
					margin-top: 0;
					margin-left: -3em;
				}

					.row.gtr-150 > * {
						padding: 0 0 0 3em;
					}

					.row.gtr-150.gtr-uniform {
						margin-top: -3em;
					}

						.row.gtr-150.gtr-uniform > * {
							padding-top: 3em;
						}

				.row.gtr-200 {
					margin-top: 0;
					margin-left: -4em;
				}

					.row.gtr-200 > * {
						padding: 0 0 0 4em;
					}

					.row.gtr-200.gtr-uniform {
						margin-top: -4em;
					}

						.row.gtr-200.gtr-uniform > * {
							padding-top: 4em;
						}

		}

		@media screen and (max-width: 1280px) {

			.row {
				display: flex;
				flex-wrap: wrap;
				box-sizing: border-box;
				align-items: stretch;
			}

				.row > * {
					box-sizing: border-box;
				}

				.row.gtr-uniform > * > :last-child {
					margin-bottom: 0;
				}

				.row.aln-left {
					justify-content: flex-start;
				}

				.row.aln-center {
					justify-content: center;
				}

				.row.aln-right {
					justify-content: flex-end;
				}

				.row.aln-top {
					align-items: flex-start;
				}

				.row.aln-middle {
					align-items: center;
				}

				.row.aln-bottom {
					align-items: flex-end;
				}

				.row > .imp-normal {
					order: -1;
				}

				.row > .col-1-normal {
					width: 8.3333333333%;
				}

				.row > .off-1-normal {
					margin-left: 8.3333333333%;
				}

				.row > .col-2-normal {
					width: 16.6666666667%;
				}

				.row > .off-2-normal {
					margin-left: 16.6666666667%;
				}

				.row > .col-3-normal {
					width: 25%;
				}

				.row > .off-3-normal {
					margin-left: 25%;
				}

				.row > .col-4-normal {
					width: 33.3333333333%;
				}

				.row > .off-4-normal {
					margin-left: 33.3333333333%;
				}

				.row > .col-5-normal {
					width: 41.6666666667%;
				}

				.row > .off-5-normal {
					margin-left: 41.6666666667%;
				}

				.row > .col-6-normal {
					width: 50%;
				}

				.row > .off-6-normal {
					margin-left: 50%;
				}

				.row > .col-7-normal {
					width: 58.3333333333%;
				}

				.row > .off-7-normal {
					margin-left: 58.3333333333%;
				}

				.row > .col-8-normal {
					width: 66.6666666667%;
				}

				.row > .off-8-normal {
					margin-left: 66.6666666667%;
				}

				.row > .col-9-normal {
					width: 75%;
				}

				.row > .off-9-normal {
					margin-left: 75%;
				}

				.row > .col-10-normal {
					width: 83.3333333333%;
				}

				.row > .off-10-normal {
					margin-left: 83.3333333333%;
				}

				.row > .col-11-normal {
					width: 91.6666666667%;
				}

				.row > .off-11-normal {
					margin-left: 91.6666666667%;
				}

				.row > .col-12-normal {
					width: 100%;
				}

				.row > .off-12-normal {
					margin-left: 100%;
				}

				.row.gtr-0 {
					margin-top: 0;
					margin-left: 0em;
				}

					.row.gtr-0 > * {
						padding: 0 0 0 0em;
					}

					.row.gtr-0.gtr-uniform {
						margin-top: 0em;
					}

						.row.gtr-0.gtr-uniform > * {
							padding-top: 0em;
						}

				.row.gtr-25 {
					margin-top: 0;
					margin-left: -0.5em;
				}

					.row.gtr-25 > * {
						padding: 0 0 0 0.5em;
					}

					.row.gtr-25.gtr-uniform {
						margin-top: -0.5em;
					}

						.row.gtr-25.gtr-uniform > * {
							padding-top: 0.5em;
						}

				.row.gtr-50 {
					margin-top: 0;
					margin-left: -1em;
				}

					.row.gtr-50 > * {
						padding: 0 0 0 1em;
					}

					.row.gtr-50.gtr-uniform {
						margin-top: -1em;
					}

						.row.gtr-50.gtr-uniform > * {
							padding-top: 1em;
						}

				.row {
					margin-top: 0;
					margin-left: -2em;
				}

					.row > * {
						padding: 0 0 0 2em;
					}

					.row.gtr-uniform {
						margin-top: -2em;
					}

						.row.gtr-uniform > * {
							padding-top: 2em;
						}

				.row.gtr-150 {
					margin-top: 0;
					margin-left: -3em;
				}

					.row.gtr-150 > * {
						padding: 0 0 0 3em;
					}

					.row.gtr-150.gtr-uniform {
						margin-top: -3em;
					}

						.row.gtr-150.gtr-uniform > * {
							padding-top: 3em;
						}

				.row.gtr-200 {
					margin-top: 0;
					margin-left: -4em;
				}

					.row.gtr-200 > * {
						padding: 0 0 0 4em;
					}

					.row.gtr-200.gtr-uniform {
						margin-top: -4em;
					}

						.row.gtr-200.gtr-uniform > * {
							padding-top: 4em;
						}

		}

		@media screen and (max-width: 980px) {

			.row {
				display: flex;
				flex-wrap: wrap;
				box-sizing: border-box;
				align-items: stretch;
			}

				.row > * {
					box-sizing: border-box;
				}

				.row.gtr-uniform > * > :last-child {
					margin-bottom: 0;
				}

				.row.aln-left {
					justify-content: flex-start;
				}

				.row.aln-center {
					justify-content: center;
				}

				.row.aln-right {
					justify-content: flex-end;
				}

				.row.aln-top {
					align-items: flex-start;
				}

				.row.aln-middle {
					align-items: center;
				}

				.row.aln-bottom {
					align-items: flex-end;
				}

				.row > .imp-narrow {
					order: -1;
				}

				.row > .col-1-narrow {
					width: 8.3333333333%;
				}

				.row > .off-1-narrow {
					margin-left: 8.3333333333%;
				}

				.row > .col-2-narrow {
					width: 16.6666666667%;
				}

				.row > .off-2-narrow {
					margin-left: 16.6666666667%;
				}

				.row > .col-3-narrow {
					width: 25%;
				}

				.row > .off-3-narrow {
					margin-left: 25%;
				}

				.row > .col-4-narrow {
					width: 33.3333333333%;
				}

				.row > .off-4-narrow {
					margin-left: 33.3333333333%;
				}

				.row > .col-5-narrow {
					width: 41.6666666667%;
				}

				.row > .off-5-narrow {
					margin-left: 41.6666666667%;
				}

				.row > .col-6-narrow {
					width: 50%;
				}

				.row > .off-6-narrow {
					margin-left: 50%;
				}

				.row > .col-7-narrow {
					width: 58.3333333333%;
				}

				.row > .off-7-narrow {
					margin-left: 58.3333333333%;
				}

				.row > .col-8-narrow {
					width: 66.6666666667%;
				}

				.row > .off-8-narrow {
					margin-left: 66.6666666667%;
				}

				.row > .col-9-narrow {
					width: 75%;
				}

				.row > .off-9-narrow {
					margin-left: 75%;
				}

				.row > .col-10-narrow {
					width: 83.3333333333%;
				}

				.row > .off-10-narrow {
					margin-left: 83.3333333333%;
				}

				.row > .col-11-narrow {
					width: 91.6666666667%;
				}

				.row > .off-11-narrow {
					margin-left: 91.6666666667%;
				}

				.row > .col-12-narrow {
					width: 100%;
				}

				.row > .off-12-narrow {
					margin-left: 100%;
				}

				.row.gtr-0 {
					margin-top: 0;
					margin-left: 0em;
				}

					.row.gtr-0 > * {
						padding: 0 0 0 0em;
					}

					.row.gtr-0.gtr-uniform {
						margin-top: 0em;
					}

						.row.gtr-0.gtr-uniform > * {
							padding-top: 0em;
						}

				.row.gtr-25 {
					margin-top: 0;
					margin-left: -0.5em;
				}

					.row.gtr-25 > * {
						padding: 0 0 0 0.5em;
					}

					.row.gtr-25.gtr-uniform {
						margin-top: -0.5em;
					}

						.row.gtr-25.gtr-uniform > * {
							padding-top: 0.5em;
						}

				.row.gtr-50 {
					margin-top: 0;
					margin-left: -1em;
				}

					.row.gtr-50 > * {
						padding: 0 0 0 1em;
					}

					.row.gtr-50.gtr-uniform {
						margin-top: -1em;
					}

						.row.gtr-50.gtr-uniform > * {
							padding-top: 1em;
						}

				.row {
					margin-top: 0;
					margin-left: -2em;
				}

					.row > * {
						padding: 0 0 0 2em;
					}

					.row.gtr-uniform {
						margin-top: -2em;
					}

						.row.gtr-uniform > * {
							padding-top: 2em;
						}

				.row.gtr-150 {
					margin-top: 0;
					margin-left: -3em;
				}

					.row.gtr-150 > * {
						padding: 0 0 0 3em;
					}

					.row.gtr-150.gtr-uniform {
						margin-top: -3em;
					}

						.row.gtr-150.gtr-uniform > * {
							padding-top: 3em;
						}

				.row.gtr-200 {
					margin-top: 0;
					margin-left: -4em;
				}

					.row.gtr-200 > * {
						padding: 0 0 0 4em;
					}

					.row.gtr-200.gtr-uniform {
						margin-top: -4em;
					}

						.row.gtr-200.gtr-uniform > * {
							padding-top: 4em;
						}

		}

		@media screen and (max-width: 840px) {

			.row {
				display: flex;
				flex-wrap: wrap;
				box-sizing: border-box;
				align-items: stretch;
			}

				.row > * {
					box-sizing: border-box;
				}

				.row.gtr-uniform > * > :last-child {
					margin-bottom: 0;
				}

				.row.aln-left {
					justify-content: flex-start;
				}

				.row.aln-center {
					justify-content: center;
				}

				.row.aln-right {
					justify-content: flex-end;
				}

				.row.aln-top {
					align-items: flex-start;
				}

				.row.aln-middle {
					align-items: center;
				}

				.row.aln-bottom {
					align-items: flex-end;
				}

				.row > .imp-narrower {
					order: -1;
				}

				.row > .col-1-narrower {
					width: 8.3333333333%;
				}

				.row > .off-1-narrower {
					margin-left: 8.3333333333%;
				}

				.row > .col-2-narrower {
					width: 16.6666666667%;
				}

				.row > .off-2-narrower {
					margin-left: 16.6666666667%;
				}

				.row > .col-3-narrower {
					width: 25%;
				}

				.row > .off-3-narrower {
					margin-left: 25%;
				}

				.row > .col-4-narrower {
					width: 33.3333333333%;
				}

				.row > .off-4-narrower {
					margin-left: 33.3333333333%;
				}

				.row > .col-5-narrower {
					width: 41.6666666667%;
				}

				.row > .off-5-narrower {
					margin-left: 41.6666666667%;
				}

				.row > .col-6-narrower {
					width: 50%;
				}

				.row > .off-6-narrower {
					margin-left: 50%;
				}

				.row > .col-7-narrower {
					width: 58.3333333333%;
				}

				.row > .off-7-narrower {
					margin-left: 58.3333333333%;
				}

				.row > .col-8-narrower {
					width: 66.6666666667%;
				}

				.row > .off-8-narrower {
					margin-left: 66.6666666667%;
				}

				.row > .col-9-narrower {
					width: 75%;
				}

				.row > .off-9-narrower {
					margin-left: 75%;
				}

				.row > .col-10-narrower {
					width: 83.3333333333%;
				}

				.row > .off-10-narrower {
					margin-left: 83.3333333333%;
				}

				.row > .col-11-narrower {
					width: 91.6666666667%;
				}

				.row > .off-11-narrower {
					margin-left: 91.6666666667%;
				}

				.row > .col-12-narrower {
					width: 100%;
				}

				.row > .off-12-narrower {
					margin-left: 100%;
				}

				.row.gtr-0 {
					margin-top: 0;
					margin-left: 0em;
				}

					.row.gtr-0 > * {
						padding: 0 0 0 0em;
					}

					.row.gtr-0.gtr-uniform {
						margin-top: 0em;
					}

						.row.gtr-0.gtr-uniform > * {
							padding-top: 0em;
						}

				.row.gtr-25 {
					margin-top: 0;
					margin-left: -0.5em;
				}

					.row.gtr-25 > * {
						padding: 0 0 0 0.5em;
					}

					.row.gtr-25.gtr-uniform {
						margin-top: -0.5em;
					}

						.row.gtr-25.gtr-uniform > * {
							padding-top: 0.5em;
						}

				.row.gtr-50 {
					margin-top: 0;
					margin-left: -1em;
				}

					.row.gtr-50 > * {
						padding: 0 0 0 1em;
					}

					.row.gtr-50.gtr-uniform {
						margin-top: -1em;
					}

						.row.gtr-50.gtr-uniform > * {
							padding-top: 1em;
						}

				.row {
					margin-top: 0;
					margin-left: -2em;
				}

					.row > * {
						padding: 0 0 0 2em;
					}

					.row.gtr-uniform {
						margin-top: -2em;
					}

						.row.gtr-uniform > * {
							padding-top: 2em;
						}

				.row.gtr-150 {
					margin-top: 0;
					margin-left: -3em;
				}

					.row.gtr-150 > * {
						padding: 0 0 0 3em;
					}

					.row.gtr-150.gtr-uniform {
						margin-top: -3em;
					}

						.row.gtr-150.gtr-uniform > * {
							padding-top: 3em;
						}

				.row.gtr-200 {
					margin-top: 0;
					margin-left: -4em;
				}

					.row.gtr-200 > * {
						padding: 0 0 0 4em;
					}

					.row.gtr-200.gtr-uniform {
						margin-top: -4em;
					}

						.row.gtr-200.gtr-uniform > * {
							padding-top: 4em;
						}

		}

		@media screen and (max-width: 736px) {

			.row {
				display: flex;
				flex-wrap: wrap;
				box-sizing: border-box;
				align-items: stretch;
			}

				.row > * {
					box-sizing: border-box;
				}

				.row.gtr-uniform > * > :last-child {
					margin-bottom: 0;
				}

				.row.aln-left {
					justify-content: flex-start;
				}

				.row.aln-center {
					justify-content: center;
				}

				.row.aln-right {
					justify-content: flex-end;
				}

				.row.aln-top {
					align-items: flex-start;
				}

				.row.aln-middle {
					align-items: center;
				}

				.row.aln-bottom {
					align-items: flex-end;
				}

				.row > .imp-mobile {
					order: -1;
				}

				.row > .col-1-mobile {
					width: 8.3333333333%;
				}

				.row > .off-1-mobile {
					margin-left: 8.3333333333%;
				}

				.row > .col-2-mobile {
					width: 16.6666666667%;
				}

				.row > .off-2-mobile {
					margin-left: 16.6666666667%;
				}

				.row > .col-3-mobile {
					width: 25%;
				}

				.row > .off-3-mobile {
					margin-left: 25%;
				}

				.row > .col-4-mobile {
					width: 33.3333333333%;
				}

				.row > .off-4-mobile {
					margin-left: 33.3333333333%;
				}

				.row > .col-5-mobile {
					width: 41.6666666667%;
				}

				.row > .off-5-mobile {
					margin-left: 41.6666666667%;
				}

				.row > .col-6-mobile {
					width: 50%;
				}

				.row > .off-6-mobile {
					margin-left: 50%;
				}

				.row > .col-7-mobile {
					width: 58.3333333333%;
				}

				.row > .off-7-mobile {
					margin-left: 58.3333333333%;
				}

				.row > .col-8-mobile {
					width: 66.6666666667%;
				}

				.row > .off-8-mobile {
					margin-left: 66.6666666667%;
				}

				.row > .col-9-mobile {
					width: 75%;
				}

				.row > .off-9-mobile {
					margin-left: 75%;
				}

				.row > .col-10-mobile {
					width: 83.3333333333%;
				}

				.row > .off-10-mobile {
					margin-left: 83.3333333333%;
				}

				.row > .col-11-mobile {
					width: 91.6666666667%;
				}

				.row > .off-11-mobile {
					margin-left: 91.6666666667%;
				}

				.row > .col-12-mobile {
					width: 100%;
				}

				.row > .off-12-mobile {
					margin-left: 100%;
				}

				.row.gtr-0 {
					margin-top: 0;
					margin-left: 0em;
				}

					.row.gtr-0 > * {
						padding: 0 0 0 0em;
					}

					.row.gtr-0.gtr-uniform {
						margin-top: 0em;
					}

						.row.gtr-0.gtr-uniform > * {
							padding-top: 0em;
						}

				.row.gtr-25 {
					margin-top: 0;
					margin-left: -0.5em;
				}

					.row.gtr-25 > * {
						padding: 0 0 0 0.5em;
					}

					.row.gtr-25.gtr-uniform {
						margin-top: -0.5em;
					}

						.row.gtr-25.gtr-uniform > * {
							padding-top: 0.5em;
						}

				.row.gtr-50 {
					margin-top: 0;
					margin-left: -1em;
				}

					.row.gtr-50 > * {
						padding: 0 0 0 1em;
					}

					.row.gtr-50.gtr-uniform {
						margin-top: -1em;
					}

						.row.gtr-50.gtr-uniform > * {
							padding-top: 1em;
						}

				.row {
					margin-top: 0;
					margin-left: -2em;
				}

					.row > * {
						padding: 0 0 0 2em;
					}

					.row.gtr-uniform {
						margin-top: -2em;
					}

						.row.gtr-uniform > * {
							padding-top: 2em;
						}

				.row.gtr-150 {
					margin-top: 0;
					margin-left: -3em;
				}

					.row.gtr-150 > * {
						padding: 0 0 0 3em;
					}

					.row.gtr-150.gtr-uniform {
						margin-top: -3em;
					}

						.row.gtr-150.gtr-uniform > * {
							padding-top: 3em;
						}

				.row.gtr-200 {
					margin-top: 0;
					margin-left: -4em;
				}

					.row.gtr-200 > * {
						padding: 0 0 0 4em;
					}

					.row.gtr-200.gtr-uniform {
						margin-top: -4em;
					}

						.row.gtr-200.gtr-uniform > * {
							padding-top: 4em;
						}

		}

		@media screen and (max-width: 480px) {

			.row {
				display: flex;
				flex-wrap: wrap;
				box-sizing: border-box;
				align-items: stretch;
			}

				.row > * {
					box-sizing: border-box;
				}

				.row.gtr-uniform > * > :last-child {
					margin-bottom: 0;
				}

				.row.aln-left {
					justify-content: flex-start;
				}

				.row.aln-center {
					justify-content: center;
				}

				.row.aln-right {
					justify-content: flex-end;
				}

				.row.aln-top {
					align-items: flex-start;
				}

				.row.aln-middle {
					align-items: center;
				}

				.row.aln-bottom {
					align-items: flex-end;
				}

				.row > .imp-mobilep {
					order: -1;
				}

				.row > .col-1-mobilep {
					width: 8.3333333333%;
				}

				.row > .off-1-mobilep {
					margin-left: 8.3333333333%;
				}

				.row > .col-2-mobilep {
					width: 16.6666666667%;
				}

				.row > .off-2-mobilep {
					margin-left: 16.6666666667%;
				}

				.row > .col-3-mobilep {
					width: 25%;
				}

				.row > .off-3-mobilep {
					margin-left: 25%;
				}

				.row > .col-4-mobilep {
					width: 33.3333333333%;
				}

				.row > .off-4-mobilep {
					margin-left: 33.3333333333%;
				}

				.row > .col-5-mobilep {
					width: 41.6666666667%;
				}

				.row > .off-5-mobilep {
					margin-left: 41.6666666667%;
				}

				.row > .col-6-mobilep {
					width: 50%;
				}

				.row > .off-6-mobilep {
					margin-left: 50%;
				}

				.row > .col-7-mobilep {
					width: 58.3333333333%;
				}

				.row > .off-7-mobilep {
					margin-left: 58.3333333333%;
				}

				.row > .col-8-mobilep {
					width: 66.6666666667%;
				}

				.row > .off-8-mobilep {
					margin-left: 66.6666666667%;
				}

				.row > .col-9-mobilep {
					width: 75%;
				}

				.row > .off-9-mobilep {
					margin-left: 75%;
				}

				.row > .col-10-mobilep {
					width: 83.3333333333%;
				}

				.row > .off-10-mobilep {
					margin-left: 83.3333333333%;
				}

				.row > .col-11-mobilep {
					width: 91.6666666667%;
				}

				.row > .off-11-mobilep {
					margin-left: 91.6666666667%;
				}

				.row > .col-12-mobilep {
					width: 100%;
				}

				.row > .off-12-mobilep {
					margin-left: 100%;
				}

				.row.gtr-0 {
					margin-top: 0;
					margin-left: 0em;
				}

					.row.gtr-0 > * {
						padding: 0 0 0 0em;
					}

					.row.gtr-0.gtr-uniform {
						margin-top: 0em;
					}

						.row.gtr-0.gtr-uniform > * {
							padding-top: 0em;
						}

				.row.gtr-25 {
					margin-top: 0;
					margin-left: -0.5em;
				}

					.row.gtr-25 > * {
						padding: 0 0 0 0.5em;
					}

					.row.gtr-25.gtr-uniform {
						margin-top: -0.5em;
					}

						.row.gtr-25.gtr-uniform > * {
							padding-top: 0.5em;
						}

				.row.gtr-50 {
					margin-top: 0;
					margin-left: -1em;
				}

					.row.gtr-50 > * {
						padding: 0 0 0 1em;
					}

					.row.gtr-50.gtr-uniform {
						margin-top: -1em;
					}

						.row.gtr-50.gtr-uniform > * {
							padding-top: 1em;
						}

				.row {
					margin-top: 0;
					margin-left: -2em;
				}

					.row > * {
						padding: 0 0 0 2em;
					}

					.row.gtr-uniform {
						margin-top: -2em;
					}

						.row.gtr-uniform > * {
							padding-top: 2em;
						}

				.row.gtr-150 {
					margin-top: 0;
					margin-left: -3em;
				}

					.row.gtr-150 > * {
						padding: 0 0 0 3em;
					}

					.row.gtr-150.gtr-uniform {
						margin-top: -3em;
					}

						.row.gtr-150.gtr-uniform > * {
							padding-top: 3em;
						}

				.row.gtr-200 {
					margin-top: 0;
					margin-left: -4em;
				}

					.row.gtr-200 > * {
						padding: 0 0 0 4em;
					}

					.row.gtr-200.gtr-uniform {
						margin-top: -4em;
					}

						.row.gtr-200.gtr-uniform > * {
							padding-top: 4em;
						}

		}

/* Container */

	.container {
		margin: 0 auto;
		max-width: calc(100% - 5em);
		width: 60em;
	}

		.container.xsmall {
			width: 15em;
		}

		.container.small {
			width: 30em;
		}

		.container.medium {
			width: 45em;
		}

		.container.large {
			width: 75em;
		}

		.container.xlarge {
			width: 90em;
		}

		.container.max {
			width: 100%;
		}

		@media screen and (max-width: 840px) {

			.container {
				width: 100% !important;
			}

		}

		@media screen and (max-width: 736px) {

			.container {
				max-width: 100%;
			}

		}

/* Section/Article */

	section.special, article.special {
		text-align: center;
	}


	header p {
		color: #999;
		position: relative;
		margin: 0 0 1.5em 0;
		font-style: italic;
	}

	header h2 + p {
		font-size: 1.25em;
		margin-top: -1em;
		line-height: 1.5em;
	}

	header h3 + p {
		font-size: 1.1em;
		margin-top: -0.85em;
		line-height: 1.5em;
	}

	header h4 + p,
	header h5 + p,
	header h6 + p {
		font-size: 0.8em;
		margin-top: -0.5em;
		line-height: 1.5em;
	}

	header.major {
		padding: 1em 0;
		text-align: center;
	}

		header.major h2 {
			margin: 0;
		}

		header.major p {
			display: inline-block;
			border-top: solid 2px #e5e5e5;
			color: #777;
			margin: 1.5em 0 0 0;
			padding: 1.5em 0 0 0;
			font-style: normal;
		}

/* Form */

	form {
		margin: 0 0 2em 0;
	}

	label {
		color: #646464;
		display: block;
		font-size: 0.9em;
		font-weight: 300;
		margin: 0 0 1em 0;
	}

	input[type="text"],
	input[type="password"],
	input[type="email"],
	select,
	textarea {
		-moz-appearance: none;
		-webkit-appearance: none;
		-ms-appearance: none;
		appearance: none;
		background-color: #f8f8f8;
		border-radius: 6px;
		border: solid 1px #e5e5e5;
		color: inherit;
		display: block;
		outline: 0;
		padding: 0 1em;
		text-decoration: none;
		width: 100%;
	}

		input[type="text"]:invalid,
		input[type="password"]:invalid,
		input[type="email"]:invalid,
		select:invalid,
		textarea:invalid {
			box-shadow: none;
		}

		input[type="text"]:focus,
		input[type="password"]:focus,
		input[type="email"]:focus,
		select:focus,
		textarea:focus {
			border-color: #e89980;
			box-shadow: 0 0 0 2px #e89980;
		}

	select {
		background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' preserveAspectRatio='none' viewBox='0 0 40 40'%3E%3Cpath d='M9.4,12.3l10.4,10.4l10.4-10.4c0.2-0.2,0.5-0.4,0.9-0.4c0.3,0,0.6,0.1,0.9,0.4l3.3,3.3c0.2,0.2,0.4,0.5,0.4,0.9 c0,0.4-0.1,0.6-0.4,0.9L20.7,31.9c-0.2,0.2-0.5,0.4-0.9,0.4c-0.3,0-0.6-0.1-0.9-0.4L4.3,17.3c-0.2-0.2-0.4-0.5-0.4-0.9 c0-0.4,0.1-0.6,0.4-0.9l3.3-3.3c0.2-0.2,0.5-0.4,0.9-0.4S9.1,12.1,9.4,12.3z' fill='%23e5e5e5' /%3E%3C/svg%3E");
		background-size: 1.25em;
		background-repeat: no-repeat;
		background-position: calc(100% - 1em) center;
		height: 3em;
		padding-right: 3em;
		text-overflow: ellipsis;
	}

		select option {
			color: #777;
			background-color: #f5f5f5;
		}

		select:focus::-ms-value {
			background-color: transparent;
		}

		select::-ms-expand {
			display: none;
		}

	input[type="text"],
	input[type="password"],
	input[type="email"],
	select {
		height: 3em;
	}

	textarea {
		padding: 0.75em 1em;
	}

	input[type="checkbox"],
	input[type="radio"] {
		-moz-appearance: none;
		-webkit-appearance: none;
		-ms-appearance: none;
		appearance: none;
		display: block;
		float: left;
		margin-right: -2em;
		opacity: 0;
		width: 1em;
		z-index: -1;
	}

		input[type="checkbox"] + label,
		input[type="radio"] + label {
			text-decoration: none;
			color: #777;
			cursor: pointer;
			display: inline-block;
			font-size: 1em;
			font-weight: 300;
			padding-left: 2.55em;
			padding-right: 0.75em;
			position: relative;
		}

			input[type="checkbox"] + label:before,
			input[type="radio"] + label:before {
				-moz-osx-font-smoothing: grayscale;
				-webkit-font-smoothing: antialiased;
				font-family: FontAwesome;
				font-style: normal;
				font-weight: normal;
				text-transform: none !important;
			}

			input[type="checkbox"] + label:before,
			input[type="radio"] + label:before {
				background: #f8f8f8;
				border-radius: 6px;
				border: solid 1px #e5e5e5;
				content: '';
				display: inline-block;
				height: 1.8em;
				left: 0;
				line-height: 1.725em;
				position: absolute;
				text-align: center;
				top: 0;
				width: 1.8em;
			}

		input[type="checkbox"]:checked + label:before,
		input[type="radio"]:checked + label:before {
			background: #666;
			border-color: #666;
			color: #ffffff;
			content: '\f00c';
		}

		input[type="checkbox"]:focus + label:before,
		input[type="radio"]:focus + label:before {
			border-color: #e89980;
			box-shadow: 0 0 0 1px #e89980;
		}

	input[type="checkbox"] + label:before {
		border-radius: 6px;
	}

	input[type="radio"] + label:before {
		border-radius: 100%;
	}

	::-webkit-input-placeholder {
		color: #999 !important;
		opacity: 1.0;
	}

	:-moz-placeholder {
		color: #999 !important;
		opacity: 1.0;
	}

	::-moz-placeholder {
		color: #999 !important;
		opacity: 1.0;
	}

	:-ms-input-placeholder {
		color: #999 !important;
		opacity: 1.0;
	}

/* Box */

	.box {
		background: #fff;
		border-radius: 6px;
		box-shadow: 0 2px 0 0 #e5e5e5;
		margin: 0 0 2em 0;
		padding: 3em;
	}

		.box > :last-child {
			margin-bottom: 0;
		}

		.box.alt {
			background: none !important;
			border-radius: 0 !important;
			box-shadow: none !important;
			margin: 0 0 2em 0;
			padding: 0 !important;
		}

		.box.features .features-row {
			/* border-top: solid 2px #e5e5e5; */
			position: relative;
		}

			.box.features .features-row:after {
				clear: both;
				content: '';
				display: block;
			}

			.box.features .features-row section {
				float: left;
				padding-top: 2em;
				width: 25%;
			}

				.box.features .features-row section :last-child {
					margin-bottom: 0;
				}

			.box.features .features-row section:nth-child(2n) {
					padding-right: 0;
				}

					/*	.box.features .features-row section:nth-child(2n):before {
						background: #e5e5e5;
						content: '';
						display: block;
						height: 100%;
						margin-left: -3em;
						position: absolute;
						top: 0;
						width: 2px;
					}

				.box.features .features-row section:nth-child(2n-1) {
					padding-left: 0;
				}

			.box.features .features-row:first-child {
				border-top: 0;
			}

				.box.features .features-row:first-child section {
					padding-top: 0;
				}

			.box.features .features-row:last-child {
				padding-bottom: 0;
			}

				.box.features .features-row:last-child section {
					padding-bottom: 0;
				}
*/
		.box.special {
			text-align: center;
		}

		.box .image.featured {
			border-radius: 0;
			display: block;
			margin: 3em 0 3em -3em;
			position: relative;
			width: calc(100% + 6em);
		}

			.box .image.featured img {
				border-radius: 0;
				display: block;
				width: 100%;
			}

			.box .image.featured:first-child {
				border-radius: 6px 6px 0 0;
				margin-bottom: 2.5em;
				margin-top: -3em;
			}

				.box .image.featured:first-child img {
					border-radius: 6px 6px 0 0;
				}

			.box .image.featured:last-child {
				border-radius: 0 0 6px 6px;
				margin-bottom: -3em;
				margin-top: 3em;
			}

				.box .image.featured:last-child img {
					border-radius: 0 0 6px 6px;
				}

/* Icon */

	.icon {
		text-decoration: none;
		border-bottom: none;
		position: relative;
	}

		.icon:before {
			-moz-osx-font-smoothing: grayscale;
			-webkit-font-smoothing: antialiased;
			font-family: FontAwesome;
			font-style: normal;
			font-weight: normal;
			text-transform: none !important;
		}

		.icon > .label {
			display: none;
		}

		.icon.major {
			background: #ddd;
			border-radius: 100%;
			color: #ffffff;
			cursor: default;
			display: inline-block;
			height: 5.5em;
			line-height: 5.5em;
			margin: 0 0 1em 0;
			width: 5.5em;
		}

		.icon.minor {
			background: #ddd;
			border-radius: 50%;
			color: #ffffff;
			cursor: default;
			display: inline-block;
			height: 2.5em;
			line-height: 2.5em;
			margin-right: 10px;
			width: 2.5em;
			padding: 0em 0.6em;
		}

		.icon.minor:hover, Button:hover{
			opacity: 0.5;
		}
	
			.icon.major:before {
				font-size: 2.5em;
			}

			.icon.major.accent1 {
				background: #666;
				color: #ffffff;
			}

			.icon.major.accent2 {
				background: #e89980;
				color: #ffffff;
			}

			.icon.major.accent3 {
				background: #7fcdb8;
				color: #ffffff;
			}

			.icon.major.accent4 {
				background: #57a1e6;
				color: #ffffff;
			}

			.icon.major.accent5 {
				background: #ff6666;
				color: #ffffff;
			}

			.icon.major.accent6 {
				background: #66d9ff;
				color: #ffffff;
			}

			.icon.major.accent7 {
				background: #40bf80;
				color: #ffffff;
			}

/* Image */

	.image {
		border-radius: 6px;
		border: 0;
		display: inline-block;
		position: relative;
	}

		.image img {
			border-radius: 6px;
			display: block;
		}

		.image.left {
			float: left;
			padding: 0 1.5em 1em 0;
			top: 0.25em;
		}

		.image.right {
			float: right;
			padding: 0 0 1em 1.5em;
			top: 0.25em;
		}

		.image.fit {
			display: block;
			margin: 0 0 2em 0;
			width: 100%;
		}

			.image.fit img {
				display: block;
				width: 100%;
			}

/* List */

	ol {
		list-style: decimal;
		margin: 0 0 2em 0;
		padding-left: 1.25em;
	}

		ol li {
			padding-left: 0.25em;
		}

	ul {
		list-style: disc;
		margin: 0 0 2em 0;
		padding-left: 1em;
	}

		ul li {
			padding-left: 0.5em;
		}

		ul.alt {
			list-style: none;
			padding-left: 0;
		}

			ul.alt li {
				border-top: solid 1px #e5e5e5;
				padding: 0.5em 0;
			}

				ul.alt li:first-child {
					border-top: 0;
					padding-top: 0;
				}

		ul.icons {
			cursor: default;
			list-style: none;
			padding-left: 0;
		}

			ul.icons li {
				display: inline-block;
				padding: 0 1.25em 0 0;
			}

				ul.icons li:last-child {
					padding-right: 0;
				}

				ul.icons li .icon {
					color: inherit;
				}

					ul.icons li .icon:before {
						font-size: 1.75em;
					}

	dl {
		margin: 0 0 2em 0;
	}

/* Actions */

	ul.actions {
		display: -moz-flex;
		display: -webkit-flex;
		display: -ms-flex;
		display: flex;
		cursor: default;
		list-style: none;
		margin-left: -1em;
		padding-left: 0;
	}

		ul.actions li {
			padding: 0 0 0 1em;
			vertical-align: middle;
		}

		ul.actions.special {
			-moz-justify-content: center;
			-webkit-justify-content: center;
			-ms-justify-content: center;
			justify-content: center;
			width: 100%;
			margin-left: 0;
		}

			ul.actions.special li:first-child {
				padding-left: 0;
			}

		ul.actions.stacked {
			-moz-flex-direction: column;
			-webkit-flex-direction: column;
			-ms-flex-direction: column;
			flex-direction: column;
			margin-left: 0;
		}

			ul.actions.stacked li {
				padding: 1.3em 0 0 0;
			}

				ul.actions.stacked li:first-child {
					padding-top: 0;
				}

		ul.actions.fit {
			width: calc(100% + 1em);
		}

			ul.actions.fit li {
				-moz-flex-grow: 1;
				-webkit-flex-grow: 1;
				-ms-flex-grow: 1;
				flex-grow: 1;
				-moz-flex-shrink: 1;
				-webkit-flex-shrink: 1;
				-ms-flex-shrink: 1;
				flex-shrink: 1;
				width: 100%;
			}

				ul.actions.fit li > * {
					width: 100%;
				}

			ul.actions.fit.stacked {
				width: 100%;
			}

		@media screen and (max-width: 480px) {

			ul.actions:not(.fixed) {
				-moz-flex-direction: column;
				-webkit-flex-direction: column;
				-ms-flex-direction: column;
				flex-direction: column;
				margin-left: 0;
				width: 100% !important;
			}

				ul.actions:not(.fixed) li {
					-moz-flex-grow: 1;
					-webkit-flex-grow: 1;
					-ms-flex-grow: 1;
					flex-grow: 1;
					-moz-flex-shrink: 1;
					-webkit-flex-shrink: 1;
					-ms-flex-shrink: 1;
					flex-shrink: 1;
					padding: 1em 0 0 0;
					text-align: center;
					width: 100%;
				}

					ul.actions:not(.fixed) li > * {
						width: 100%;
					}

					ul.actions:not(.fixed) li:first-child {
						padding-top: 0;
					}

					ul.actions:not(.fixed) li input[type="submit"],
					ul.actions:not(.fixed) li input[type="reset"],
					ul.actions:not(.fixed) li input[type="button"],
					ul.actions:not(.fixed) li button,
					ul.actions:not(.fixed) li .button {
						width: 100%;
					}

						ul.actions:not(.fixed) li input[type="submit"].icon:before,
						ul.actions:not(.fixed) li input[type="reset"].icon:before,
						ul.actions:not(.fixed) li input[type="button"].icon:before,
						ul.actions:not(.fixed) li button.icon:before,
						ul.actions:not(.fixed) li .button.icon:before {
							margin-left: -0.5em;
						}

		}

/* Table */

	.table-wrapper {
		-webkit-overflow-scrolling: touch;
		overflow-x: auto;
	}

	table {
		margin: 0 0 2em 0;
		width: 100%;
	}

		table tbody tr {
			border: solid 1px #e5e5e5;
			border-left: 0;
			border-right: 0;
		}

			table tbody tr:nth-child(2n + 1) {
				background-color: #f8f8f8;
			}

		table td {
			padding: 0.75em 0.75em;
		}

		table th {
			color: #646464;
			font-size: 0.9em;
			font-weight: 300;
			padding: 0 0.75em 0.75em 0.75em;
			text-align: left;
		}

		table thead {
			border-bottom: solid 2px #e5e5e5;
		}

		table tfoot {
			border-top: solid 2px #e5e5e5;
		}

		table.alt {
			border-collapse: separate;
		}

			table.alt tbody tr td {
				border: solid 1px #e5e5e5;
				border-left-width: 0;
				border-top-width: 0;
			}

				table.alt tbody tr td:first-child {
					border-left-width: 1px;
				}

			table.alt tbody tr:first-child td {
				border-top-width: 1px;
			}

			table.alt thead {
				border-bottom: 0;
			}

			table.alt tfoot {
				border-top: 0;
			}

/* Button */
/*
	input[type="submit"],
	input[type="reset"],
	input[type="button"],
	.button {
		-moz-appearance: none;
		-webkit-appearance: none;
		-ms-appearance: none;
		appearance: none;
		-moz-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
		-webkit-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
		-ms-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
		transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
		background-color: #666;
		border-radius: 6px;
		border: 0;
		color: #ffffff;
		cursor: pointer;
		display: inline-block;
		font-weight: 400;
		height: 3em;
		line-height: 3em;
		padding: 0 2em;
		text-align: center;
		text-decoration: none;
		white-space: nowrap;
	}

		input[type="submit"]:hover,
		input[type="reset"]:hover,
		input[type="button"]:hover,
		.button:hover {
		background-color: #737373;
		}

		input[type="submit"]:active,
		input[type="reset"]:active,
		input[type="button"]:active,
		.button:active {
			background-color: #595959;
		}

		input[type="submit"].icon,
		input[type="reset"].icon,
		input[type="button"].icon,
		.button.icon {
			padding-left: 1.35em;
		}

			input[type="submit"].icon:before,
			input[type="reset"].icon:before,
			input[type="button"].icon:before,
			.button.icon:before {
				margin-right: 0.5em;
			}

		input[type="submit"].fit,
		input[type="reset"].fit,
		input[type="button"].fit,
		.button.fit {
			width: 100%;
		}

		input[type="submit"].small,
		input[type="reset"].small,
		input[type="button"].small,
		.button.small {
			font-size: 0.8em;
			height: 2.7em;
			line-height: 2.7em;
		}

		input[type="submit"].large,
		input[type="reset"].large,
		input[type="button"].large,
		.button.large {
			font-size: 1.25em;
			height: 2.7em;
			line-height: 2.7em;
		}

		input[type="submit"].alt,
		input[type="reset"].alt,
		input[type="button"].alt,
		.button.alt {
			background-color: transparent;
			box-shadow: inset 0 0 0 2px #e5e5e5;
			color: #777;
		}

			input[type="submit"].alt:hover,
			input[type="reset"].alt:hover,
			input[type="button"].alt:hover,
			.button.alt:hover {
				background-color: #f8f8f8;
			}

			input[type="submit"].alt:active,
			input[type="reset"].alt:active,
			input[type="button"].alt:active,
			.button.alt:active {
				background-color: #f0f0f0;
			}

			input[type="submit"].alt.icon:before,
			input[type="reset"].alt.icon:before,
			input[type="button"].alt.icon:before,
			.button.alt.icon:before {
				color: #999;
			}

		input[type="submit"].primary,
		input[type="reset"].primary,
		input[type="button"].primary,
		.button.primary {
			background-color: #e89980;
			color: #ffffff !important;
		}

			input[type="submit"].primary:hover,
			input[type="reset"].primary:hover,
			input[type="button"].primary:hover,
			.button.primary:hover {
				background-color: #ecaa96;
			}

			input[type="submit"].primary:active,
			input[type="reset"].primary:active,
			input[type="button"].primary:active,
			.button.primary:active {
				background-color: #e4886a;
			}

		input[type="submit"].disabled, input[type="submit"]:disabled,
		input[type="reset"].disabled,
		input[type="reset"]:disabled,
		input[type="button"].disabled,
		input[type="button"]:disabled,
		.button.disabled,
		.button:disabled {
			background-color: #777 !important;
			box-shadow: inset 0 -0.15em 0 0 rgba(0, 0, 0, 0.15);
			color: #f5f5f5 !important;
			cursor: default;
			opacity: 0.25;
		}

/* Header */

	body.landing #page-wrapper {
		padding-top: 0;
	}

	@-moz-keyframes reveal-header {
		0% {
			top: -5em;
		}

		100% {
			top: 0;
		}
	}

	@-webkit-keyframes reveal-header {
		0% {
			top: -5em;
		}

		100% {
			top: 0;
		}
	}

	@-ms-keyframes reveal-header {
		0% {
			top: -5em;
		}

		100% {
			top: 0;
		}
	}

	@keyframes reveal-header {
		0% {
			top: -5em;
		}

		100% {
			top: 0;
		}
	}

	#header {
		background: #c8102e;
		color: #bbb;
		cursor: default;
		height: 2.50em;
		left: 0;
		line-height: 3.25em;
		top: 0;
		width: 100%;
		z-index: 10;
	}

		#header h1 {
			color: inherit;
			height: inherit;
			left: 1.25em;
			line-height: inherit;
			margin: 0;
			padding: 0;
			position: absolute;
			top: 0;
			left: 75px;
		}

			#header h1 a {
				color: #fff;
				font-weight: bold;
				border: 0;
			}

		#header nav {
			height: inherit;
			line-height: inherit;
			position: absolute;
			/*right: 0.75em;
			top: -6px;*/
			vertical-align: middle;
		}

			#header nav > ul {
				list-style: none;
				margin: 0;
				padding-left: 0;
			}

				#header nav > ul > li {
					display: inline-block;
					padding-left: 0;
				}

					#header nav > ul > li > ul {
						display: none;
					}

					#header nav > ul > li a {
						display: inline-block;
						height: 2em;
						line-height: 1.95em;
						padding: 0 1em;
						border-radius: 6px;
					}

					#header nav > ul > li a:not(.button) {
						color: #fff;
						display: inline-block;
						text-decoration: none;
						border: 0;
					}

						#header nav > ul > li a:not(.button).icon:before {
							color: #999;
							margin-right: 0.5em;
						}

					#header nav > ul > li:first-child {
						margin-left: 0;
					}

					#header nav > ul > li.active a:not(.button) {
						background-color: rgba(153, 153, 153, 0.25);
					}

					#header nav > ul > li .button {
						margin: 0 0 0 0.5em;
						position: relative;
					}

		#header input[type="submit"],
		#header input[type="reset"],
		#header input[type="button"],
		#header .button {
			background-color: transparent;
			box-shadow: inset 0 0 0 2px #999;
			color: #fff;
		}

			#header input[type="submit"]:hover,
			#header input[type="reset"]:hover,
			#header input[type="button"]:hover,
			#header .button:hover {
				background-color: rgba(153, 153, 153, 0.25);
			}

			#header input[type="submit"]:active,
			#header input[type="reset"]:active,
			#header input[type="button"]:active,
			#header .button:active {
				background-color: rgba(153, 153, 153, 0.5);
			}

		#header .container {
			position: relative;
		}

			#header .container h1 {
				left: 0;
			}

			#header .container nav {
				right: 0;
			}

		#header.reveal {
			-moz-animation: reveal-header 0.5s;
			-webkit-animation: reveal-header 0.5s;
			-ms-animation: reveal-header 0.5s;
			animation: reveal-header 0.5s;
		}

		#header.alt {
			-moz-animation: none;
			-webkit-animation: none;
			-ms-animation: none;
			animation: none;
			color: rgba(255, 255, 255, 0.75);
			position: absolute;
		}

			#header.alt nav > ul > li a:not(.button).icon:before {
				color: rgba(255, 255, 255, 0.75);
			}

			#header.alt nav > ul > li.active a:not(.button) {
				background-color: rgba(255, 255, 255, 0.2);
			}

			#header.alt input[type="submit"],
			#header.alt input[type="reset"],
			#header.alt input[type="button"],
			#header.alt .button {
				box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.5);
			}

				#header.alt input[type="submit"]:hover,
				#header.alt input[type="reset"]:hover,
				#header.alt input[type="button"]:hover,
				#header.alt .button:hover {
					background-color: rgba(255, 255, 255, 0.1);
				}

				#header.alt input[type="submit"]:active,
				#header.alt input[type="reset"]:active,
				#header.alt input[type="button"]:active,
				#header.alt .button:active {
					background-color: rgba(255, 255, 255, 0.2);
				}

	.dropotron {
		background: #fff;
		border-radius: 6px;
		box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.065), inset 0 -1px 0 0 #fff, inset 0 0 0 1px rgba(229, 229, 229, 0.5);
		list-style: none;
		margin: calc(-0.5em + 1px) 0 0 1px;
		padding: 0.5em 0;
		width: 11em;
	}

		.dropotron li {
			padding: 0;
		}

			.dropotron li a, .dropotron li span {
				-moz-transition: none;
				-webkit-transition: none;
				-ms-transition: none;
				transition: none;
				border: 0;
				border-top: solid 1px #f2f2f2;
				color: inherit;
				display: block;
				padding: 0.15em 1em;
			}

			.dropotron li:first-child > a, .dropotron li:first-child > span {
				border-top: 0;
			}

			.dropotron li.active > a, .dropotron li.active > span, .dropotron li:hover > a, .dropotron li:hover > span {
				background: #e89980;
				color: #ffffff;
			}

		.dropotron.level-0 {
			font-size: 0.9em;
			margin: 1em 0 0 0;
		}

			.dropotron.level-0:before {
				-moz-transform: rotate(45deg);
				-webkit-transform: rotate(45deg);
				-ms-transform: rotate(45deg);
				transform: rotate(45deg);
				background: #fff;
				border: solid 1px rgba(229, 229, 229, 0.5);
				border-bottom: 0;
				border-right: 0;
				box-shadow: -0.25em -0.125em 0.125em 0 rgba(0, 0, 0, 0.015);
				content: '';
				display: block;
				height: 0.75em;
				position: absolute;
				right: 1.25em;
				top: -0.375em;
				width: 0.75em;
				z-index: 0;
			}

/* Banner */

	#banner {
		background-attachment: fixed;
		background-image: url("../images/banner6.jpg");
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		color: #fff;
		padding: 12em 0 20em 0;
		text-align: center;
	}
	
		#banner :last-child {
			margin-bottom: 0;
		}

		#banner h2, #banner h3, #banner h4, #banner h5, #banner h6 {
			color: #2b2b2b;
		}

		#banner h2 {
			font-size: 3.5em;
			line-height: 1em;
			margin: 0 0 0.5em 0;
			padding: 0;
		}

		#banner p {
			font-size: 1.25em;
			margin-bottom: 1.75em;
		}

		#banner input[type="submit"],
		#banner input[type="reset"],
		#banner input[type="button"],
		#banner .button {
			background-color: transparent;
			box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.5);
			color: #fff;
			min-width: 12em;
		}

			#banner input[type="submit"]:hover,
			#banner input[type="reset"]:hover,
			#banner input[type="button"]:hover,
			#banner .button:hover {
				background-color: rgba(255, 255, 255, 0.1);
			}

			#banner input[type="submit"]:active,
			#banner input[type="reset"]:active,
			#banner input[type="button"]:active,
			#banner .button:active {
				background-color: rgba(255, 255, 255, 0.2);
			}

			#banner input[type="submit"].primary,
			#banner input[type="reset"].primary,
			#banner input[type="button"].primary,
			#banner .button.primary {
				background: #fff;
				color: #666 !important;
			}

/* Main */

	#main {
		padding: 4em 0;
	}

		#main > header {
			text-align: center;
			margin: 0 0 3em 0;
		}

			#main > header h2 {
				font-size: 2.75em;
				margin: 0;
			}

			#main > header p {
				border-top: solid 2px #e5e5e5;
				color: #777;
				display: inline-block;
				font-style: normal;
				margin: 1em 0 0 0;
				padding: 1em 0 1.25em 0;
			}

	body #main {
		/* margin-top: -10em; */
	}

/* Footer */

	#footer {
		/*background: #f0f0f0;
		padding: 2em 0 6em 0;*/
		text-align: left;
		position: relative;
		z-index: 10;
		margin-top: -18px;
	}

		#footer .icons a {
			-moz-transition: opacity 0.2s ease-in-out;
			-webkit-transition: opacity 0.2s ease-in-out;
			-ms-transition: opacity 0.2s ease-in-out;
			transition: opacity 0.2s ease-in-out;
			opacity: 0.35;
		}

			#footer .icons a:hover {
				opacity: 0.75;
			}

		#footer .copyright {
			color: #999;
			font-size: 0.9em;
			line-height: 1em;
			margin: 2em 0 0 0;
			padding: 0;
			text-align: center;
		}

			#footer .copyright a {
				color: inherit;
			}

			#footer .copyright li {
				border-left: solid 1px #dddddd;
				display: inline-block;
				list-style: none;
				margin-left: 1em;
				padding-left: 1em;
			}

				#footer .copyright li:first-child {
					border-left: 0;
					margin-left: 0;
					padding-left: 0;
				}

/* CTA */

	#cta {
		background: #e89980;
		color: #f9e6df;
		padding: 3.5em 0 4em 0;
		text-align: center;
	}

		#cta h2, #cta h3, #cta h4, #cta h5, #cta h6 {
			color: inherit;
		}

		#cta form {
			margin: 0 auto;
			max-width: 100%;
			width: 25em;
		}

		#cta input[type="submit"],
		#cta input[type="reset"],
		#cta input[type="button"],
		#cta .button {
			box-shadow: none;
			background: #fff;
			color: #e89980;
		}

		#cta input[type="text"],
		#cta input[type="password"],
		#cta input[type="email"],
		#cta select,
		#cta textarea {
			color: #ffffff;
			background: rgba(255, 255, 255, 0.15);
			border: 0;
		}

			#cta input[type="text"]:focus,
			#cta input[type="password"]:focus,
			#cta input[type="email"]:focus,
			#cta select:focus,
			#cta textarea:focus {
				box-shadow: inset 0 0 0 2px #fff;
			}

		#cta ::-webkit-input-placeholder {
			color: rgba(255, 255, 255, 0.75) !important;
		}

		#cta :-moz-placeholder {
			color: rgba(255, 255, 255, 0.75) !important;
		}

		#cta ::-moz-placeholder {
			color: rgba(255, 255, 255, 0.75) !important;
		}

		#cta :-ms-input-placeholder {
			color: rgba(255, 255, 255, 0.75) !important;
		}

		#cta .formerize-placeholder {
			color: rgba(255, 255, 255, 0.75) !important;
		}

/* Wide */

	@media screen and (max-width: 1680px) {

		/* Basic */

			body, input, select, textarea {
				font-size: 13pt;
			}

		/* Banner */

			#banner {
				padding: 10em 0 18em 0;
			}

	}

/* Normal */

	@media screen and (max-width: 1280px) {

		/* Basic */

			body, input, select, textarea {
				font-size: 11pt;
			}

		/* Header */

			.dropotron.level-0 {
				font-size: 1em;
			}

		/* Banner */

			#banner {
				background-attachment: scroll;
			}

				#banner h2 {
					font-size: 3.5em;
				}

	}

/* Narrow */

	@media screen and (max-width: 980px) {

		/* Basic */

			body, input, select, textarea {
				font-size: 11pt;
			}

	}

/* Narrower */

	#navPanel, #navButton {
		display: none;
	}

	@media screen and (max-width: 840px) {

		/* Basic */

			html, body {
				overflow-x: hidden;
			}

			body, input, select, textarea {
				font-size: 11pt;
			}

			h2 br, h3 br, h4 br, h5 br, h6 br {
				display: none;
			}

		/* Section/Article */

			header br {
				display: none;
			}

			header.major {
				padding: 0 4em;
			}

		/* Box */

			.box {
				padding: 3em 2em;
			}

				.box.features > section {
					padding: 3em 2em;
				}

				.box .image.featured {
					margin-left: -2em;
					width: calc(100% + 4em);
				}

					.box .image.featured:first-child {
						margin-bottom: 3em;
						margin-top: -3em;
					}

					.box .image.featured:last-child {
						margin-bottom: -3em;
						margin-top: 3em;
					}

		/* Header */

		/*	#page-wrapper {
				padding-top: 0;
			}

			/*#header {
				display: none;
			}*/

		/* Banner */

		/* Nav */

			#page-wrapper {
				-moz-backface-visibility: hidden;
				-webkit-backface-visibility: hidden;
				-ms-backface-visibility: hidden;
				backface-visibility: hidden;
				-moz-transition: -moz-transform 0.5s ease;
				-webkit-transition: -webkit-transform 0.5s ease;
				-ms-transition: -ms-transform 0.5s ease;
				transition: transform 0.5s ease;
				padding-bottom: 1px;
			}

			#navButton {
				-moz-backface-visibility: hidden;
				-webkit-backface-visibility: hidden;
				-ms-backface-visibility: hidden;
				backface-visibility: hidden;
				-moz-transition: -moz-transform 0.5s ease;
				-webkit-transition: -webkit-transform 0.5s ease;
				-ms-transition: -ms-transform 0.5s ease;
				transition: transform 0.5s ease;
				display: block;
				height: 44px;
				left: 0;
				position: fixed;
				top: 0;
				width: 100%;
				z-index: 10001;
			}

				#navButton .toggle {
					text-decoration: none;
					height: 100%;
					left: 0;
					position: absolute;
					top: 0;
					width: 100%;
					border: 0;
					outline: 0;
				}

					#navButton .toggle:before {
						-moz-osx-font-smoothing: grayscale;
						-webkit-font-smoothing: antialiased;
						font-family: FontAwesome;
						font-style: normal;
						font-weight: normal;
						text-transform: none !important;
					}

					#navButton .toggle:before {
						background: rgba(192, 192, 192, 0.75);
						border-radius: 6px;
						color: #fff;
						content: '\f0c9';
						display: block;
						font-size: 16px;
						height: 2.25em;
						left: 0.5em;
						line-height: 2.25em;
						position: absolute;
						text-align: center;
						top: 0.5em;
						width: 3.5em;
					}

			#navPanel {
				-moz-backface-visibility: hidden;
				-webkit-backface-visibility: hidden;
				-ms-backface-visibility: hidden;
				backface-visibility: hidden;
				-moz-transform: translateX(-275px);
				-webkit-transform: translateX(-275px);
				-ms-transform: translateX(-275px);
				transform: translateX(-275px);
				-moz-transition: -moz-transform 0.5s ease;
				-webkit-transition: -webkit-transform 0.5s ease;
				-ms-transition: -ms-transform 0.5s ease;
				transition: transform 0.5s ease;
				display: block;
				height: 100%;
				left: 0;
				overflow-y: auto;
				position: fixed;
				top: 0;
				width: 275px;
				z-index: 10002;
				background: #2b2b2b;
				color: #bbb;
			}

				#navPanel .link {
					border-bottom: 0;
					border-top: solid 1px rgba(255, 255, 255, 0.05);
					color: #bbb;
					display: block;
					height: 44px;
					line-height: 44px;
					padding: 0 1em 0 1em;
					text-decoration: none;
				}

					#navPanel .link:first-child {
						border-top: 0;
					}

					#navPanel .link.depth-0 {
						color: #fff;
					}

					#navPanel .link .indent-1 {
						display: inline-block;
						width: 1em;
					}

					#navPanel .link .indent-2 {
						display: inline-block;
						width: 2em;
					}

					#navPanel .link .indent-3 {
						display: inline-block;
						width: 3em;
					}

					#navPanel .link .indent-4 {
						display: inline-block;
						width: 4em;
					}

					#navPanel .link .indent-5 {
						display: inline-block;
						width: 5em;
					}

			body.navPanel-visible #page-wrapper {
				-moz-transform: translateX(275px);
				-webkit-transform: translateX(275px);
				-ms-transform: translateX(275px);
				transform: translateX(275px);
			}

			body.navPanel-visible #navButton {
				-moz-transform: translateX(275px);
				-webkit-transform: translateX(275px);
				-ms-transform: translateX(275px);
				transform: translateX(275px);
			}

			body.navPanel-visible #navPanel {
				-moz-transform: translateX(0);
				-webkit-transform: translateX(0);
				-ms-transform: translateX(0);
				transform: translateX(0);
			}

	}

/* Mobile */

	@media screen and (max-width: 736px) {

		/* Basic */

			body, input, select, textarea {
				font-size: 11pt;
			}

			h2 {
				font-size: 1.75em;
				line-height: 1.35em;
				letter-spacing: -0.025em;
			}

			h3 {
				font-size: 1.5em;
			}

			h4 {
				font-size: 1em;
			}

		/* Section/Article */

			header.major {
				padding: 1em;
			}

				header.major h2, header.major p {
					padding-left: 0.5em;
					padding-right: 0.5em;
				}

		/* Box */

			.box {
				margin: 1em;
				overflow-x: hidden;
				padding: 2em 2em !important;
			}

				.box.features .features-row {
					border-top: 0;
					padding: 0;
				}

					.box.features .features-row section {
						border: 0;
						border-top: solid 1px #e5e5e5 !important;
						float: none;
						margin: 2em 0 0 0 !important;
						padding: 2em 0 0 0 !important;
						width: 100%;
					}

					.box.features .features-row:first-child section:first-child {
						border-top: 0 !important;
						margin-top: 0 !important;
						padding-top: 0 !important;
					}

				.box .image.featured {
					margin-left: -2em;
					width: calc(100% + 4em);
				}

					.box .image.featured:first-child {
						margin-bottom: 2em;
						margin-top: -2em;
					}

					.box .image.featured:last-child {
						margin-bottom: -2em;
						margin-top: 2em;
					}

		/* Banner */

			#banner {
				padding: 4em 0;
			}

				#banner h2 {
					font-size: 2.25em;
				}

				#banner p {
					font-size: 1.25em;
				}

		/* Main */

			#main {
				padding: 4em 0 0 0;
			}

				#main > header {
					margin: 0 2em 1.5em 2em;
				}

					#main > header h2 {
						font-size: 2em;
					}

					#main > header p {
						font-size: 1em;
						padding-bottom: 1em;
					}

			body.landing #main {
				padding: 0;
				margin-top: 0;
			}

		/* Footer */

		/*	#footer {
				padding: 4em 0;
			}*/

				#footer .copyright li {
					border-left: 0;
					display: block;
					line-height: 2em;
					margin-left: 0;
					padding-left: 0;
				}

	}

/* Mobile (Portrait) */

	@media screen and (max-width: 480px) {

		/* Basic */

			html, body {
				min-width: 320px;
			}

			body, input, select, textarea {
				font-size: 11pt;
			}

		/* Section/Article */

			header.major {
				padding: 0;
			}

		/* List */

			ul.actions {
				margin: 0 0 2em 0;
			}

				ul.actions li {
					display: block;
					padding: 1em 0 0 0;
					text-align: center;
					width: 100%;
				}

					ul.actions li:first-child {
						padding-top: 0;
					}

					ul.actions li > * {
						width: 100%;
						margin: 0 !important;
					}

						ul.actions li > *.icon:before {
							margin-left: -2em;
						}

				ul.actions.small li {
					padding: 0.5em 0 0 0;
				}

					ul.actions.small li:first-child {
						padding-top: 0;
					}

		/* Box */

			.box {
				border-radius: 0;
				box-shadow: none;
				margin: 1em 0 0 0;
				padding: 3em 1em !important;
			}

				.box.features .features-row section {
					margin: 3em 0 0 0 !important;
					padding: 3em 0 0 0 !important;
				}

				.box .image.featured {
					border-radius: 0;
					margin-left: -1em;
					width: calc(100% + 2em);
				}

					.box .image.featured img {
						border-radius: 0 !important;
					}

					.box .image.featured:first-child {
						margin-bottom: 3em;
						margin-top: -3em;
					}

					.box .image.featured:last-child {
						margin-bottom: -3em;
						margin-top: 3em;
					}

		/* Banner */
			#banner {
				padding: 5em 3em 4em 3em;
			}

		/* Main */

			#main > .box:first-child {
				margin-top: 0;
			}

		/* CTA */

			#cta {
				padding: 2.5em 1em 3em 1em;
			}

	}

	.searchBox{
		margin-left: auto; 
		margin-right: auto; 
		color: #000000;
		width: auto;
		}

	.searchButton{
		/*background-color:#003087;*/
		background-color:#0E4C8F;
		border-color: #ccc;
		color: #ffffff;
		font-size: 17px;
		padding: 0.5em 1em;
		font-weight: bold;
		border-radius: 4px;
	}
	
	.searchButton:hover {
		background-color: #555;
		color: #ffffff;
		text-decoration: none;
	  }

	 h4{
		font-weight: 'bold';
	 } 

	 h4:hover {
		 color: #003087;
	 }

	 .sui-layout {
		/*z-index: 15;
		position: absolute;
		margin-top: 50px;*/
	 }

	 .sui-layout-header {
		width:75%;
		margin-left: auto;
		margin-right: auto;
		height: 100px;
	}
 	
	#loader {
		margin-left: auto;
		margin-right: auto;
		display: block;
		position: relative;
		margin-top: 10%;
	}

	.miniFilter, .miniDonate{
		display: none;
	}	

	@media only screen and (max-width: 800px) {
		.miniFilter {
			display: block;
			margin-top: 15px;
			margin-bottom: 15px;
		}
		.miniDonate{
			display: block;
		}
		.donate{
			display: none;
		}
	}	

	/* Position and sizing of burger button */
.bm-burger-button {
	position: fixed;
	width: 23px;
	height: 17px;
	left: 15px;
	top: 11px;
  }
  
  /* Color/shape of burger icon bars */
  .bm-burger-bars {
	background: #000000;
  }
  
  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
	background: #ffff;
  }
  
  /* Position and sizing of clickable cross button */
  .bm-cross-button {
	height: 24px;
	width: 24px;
	background: #000;
  }
  
  /* Color/shape of close button cross */
  .bm-cross {
	background: #bdc3c7;
  }
  
  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
	position: fixed;
	height: 100%;
  }
  
  /* General sidebar styles */
  .bm-menu {
	background: #ddd;
	padding: 2.5em 1.5em 0;
	font-size: 1.15em;
  }
  
  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
	fill: #373a47;
  }
  
  /* Wrapper for item list */
  .bm-item-list {
	color: #b8b7ad;
	padding: 0.8em;
  }
  
  /* Individual item */
  .bm-item {
	display: inline-block;
  }
  
  /* Styling of overlay */
  .bm-overlay {
	background: rgba(0, 0, 0, 0.3);
  }

  .flip_container {
	position: relative;
	overflow: hidden;
	width: 100%;
	padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
  }
  
  /* Then style the iframe to fit in the container div with full height and width */
  .responsive-iframe {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	width: 100%;
	height: 100%;
  }
  
  /* Our Team */
  /* Three columns side by side */
.column {
	float: left;
	width: 33.3%;
	margin-bottom: 16px;
	padding: 0 8px;
  }
  
  /* Display the columns below each other instead of side by side on small screens */
  @media screen and (max-width: 650px) {
	.column {
	  width: 100%;
	  display: block;
	}
  }
  
  /* Add some shadows to create a card effect */
  .card {
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
	/*height: 900px;
	overflow: auto;*/
  }

  .cardImage {
	height: 375px;
	overflow: hidden;
  }
  
  /* Some left and right padding inside the container */
  .containerText {
	padding: 0 25px;
	height: 500px;
	overflow: auto;
  }
  
  /* Clear floats */
  .containerText::after, .row::after {
	content: "";
	clear: both;
	display: table;
  }
  
  .title {
	color: grey;
	font-style: italic;
  }

  .containerTitle{
	margin-top: 10px;
	text-align: center;
	margin-bottom: -25px;
  }
  
  /* Profile Main 

	.profilemain {
		display: -moz-flex;
		display: -webkit-flex;
		display: -ms-flex;
		display: flex;
		position: relative;
		margin: 0;
		overflow-x: hidden;
	}*/

		.profilemain > .content {
			width: 45em;
			max-width: calc(100% - 4em);
			margin: 0 auto;
		}

			.profilemain > .content > :last-child {
				margin-bottom: 0;
			}

		.profilemain.fullscreen {
			min-height: 100%;
		}

		.profilemain.style1 {
			-moz-align-items: center;
			-webkit-align-items: center;
			-ms-align-items: center;
			align-items: center;
			-moz-justify-content: center;
			-webkit-justify-content: center;
			-ms-justify-content: center;
			justify-content: center;
			text-align: center;
			padding: 3em 0 3em 0;
		}

			.profilemain.style1 h2 {
				font-size: 4.25em;
				line-height: 1em;
			}

			.profilemain.style1 > .content {
				-moz-transition: opacity 1s ease;
				-webkit-transition: opacity 1s ease;
				-ms-transition: opacity 1s ease;
				transition: opacity 1s ease;
				-moz-transform: translateZ(0);
				-webkit-transform: translateZ(0);
				-ms-transform: translateZ(0);
				transform: translateZ(0);
				opacity: 1.0;
				margin: 0;
			}

			.profilemain.style1.inactive > .content {
				opacity: 0;
			}

		.profilemain.style2 {
			-moz-align-items: center;
			-webkit-align-items: center;
			-ms-align-items: center;
			align-items: center;
			-moz-justify-content: center;
			-webkit-justify-content: center;
			-ms-justify-content: center;
			justify-content: center;
			padding: 3em 0 3em 0;
			overflow: hidden;
		}

			.profilemain.style2 > .content {
				-moz-transition: -moz-transform 1s ease;
				-webkit-transition: -webkit-transform 1s ease;
				-ms-transition: -ms-transform 1s ease;
				transition: transform 1s ease;
				-moz-transform: translateZ(0);
				-webkit-transform: translateZ(0);
				-ms-transform: translateZ(0);
				transform: translateZ(0);
				position: relative;
				width: 35%;
				margin: 0;
			}

			.profilemain.style2.left {
				-moz-justify-content: -moz-flex-start;
				-webkit-justify-content: -webkit-flex-start;
				-ms-justify-content: -ms-flex-start;
				justify-content: flex-start;
			}

			.profilemain.style2.right {
				-moz-justify-content: -moz-flex-end;
				-webkit-justify-content: -webkit-flex-end;
				-ms-justify-content: -ms-flex-end;
				justify-content: flex-end;
			}

			.profilemain.style2.inactive.left > .content {
				-moz-transform: translateX(-100%);
				-webkit-transform: translateX(-100%);
				-ms-transform: translateX(-100%);
				transform: translateX(-100%);
			}

			.profilemain.style2.inactive.right > .content {
				-moz-transform: translateX(100%);
				-webkit-transform: translateX(100%);
				-ms-transform: translateX(100%);
				transform: translateX(100%);
			}

		.profilemain.style3 {
			text-align: center;
			padding: 2em 0 2em 0;
		}

			.profilemain.style3 .content > header {
				margin-bottom: 2em;
			}

			.profilemain.style3.primary {
				background: #ffffff;
			}

			.profilemain.style3.secondary {
				background: #f5f6f7;
			}

		.profilemain.dark {
			color: #ffffff;
		}

			.profilemain.dark a {
				color: inherit;
			}

			.profilemain.dark .button.style2 {
				border-color: #ffffff;
			}

				.profilemain.dark .button.style2:hover {
					background-color: rgba(255, 255, 255, 0.125);
				}

				.profilemain.dark .button.style2:active {
					background-color: rgba(255, 255, 255, 0.25);
				}


		body.is-touch .profilemain {
			background-attachment: scroll !important;
		}

		@media screen and (max-width: 1920px) {

			.profilemain.style2 .content {
				width: 40%;
			}

		}

		@media screen and (max-width: 1280px) {

			.profilemain.style2 .content {
				width: 50%;
			}

		}

		@media screen and (max-width: 1000px) {

			.profilemain.style2 .content {
				width: 60%;
			}

		}

		@media screen and (max-width: 736px) {

			.profilemain > .content br {
				display: none;
			}

			.profilemain.fullscreen {
				height: auto !important;
			}

			.profilemain.style1 {
				padding: 4em 15px 4em 15px;
			}

				.profilemain.style1 h2 {
					font-size: 3em;
				}

			.profilemain.style2 {
				padding: 6em 15px 6em 15px;
			}

				.profilemain.style2:before, .profilemain.style2:after {
					display: none !important;
				}

				.profilemain.style2 .button.anchored {
					display: none;
				}

				.profilemain.style2 .content {
					width: 100%;
					max-width: 100%;
					text-align: center;
					-moz-transform: none;
					-webkit-transform: none;
					-ms-transform: none;
					transform: none;
				}

			.profilemain.style3 {
				text-align: center;
				padding: 1em 10px 3em 10px;
			}

		}

		@media screen and (max-width: 480px) {

			.profilemain > .content {
				max-width: calc(100% - 1.5em);
			}

		}


/* Intro */

	#intro {
		background: url("https://archivenepal.s3.amazonaws.com/sushila_singh_collection/access/1.jpg");
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		background-attachment: fixed;
		height: 1150px;
	}

	@media screen and (max-width: 1366px){
		#intro {
			background-attachment: scroll;
		}

	}

/* One */

	#one {
		background: url("https://archivenepal.s3.amazonaws.com/sushila_singh_collection/homepage/bio.jpg");
		background-size: cover;
		background-repeat: no-repeat;
		background-attachment: fixed;
		background-position: center;
		position: relative;
		z-index: 10;
	}
	
	@media screen and (max-width: 1366px){		
		#one{
			background-attachment: scroll;
		}
	}

/* Two */

#two {
	background: url("https://archivenepal.s3.amazonaws.com/sushila_singh_collection/homepage/contact.jpg");
	background-size: cover;
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-position: center;
	position: relative;
	z-index: 10;
}

@media screen and (max-width: 1366px){		
	#two{
		background-attachment: scroll;
		background-position: left;
	}
}
			.profilebox {
				background: #fff;
				height: 280px;
				border-radius: 6px;
				box-shadow: 0 2px 0 0 #e5e5e5;
				margin: 0 0 2em 0;
				padding: 3em;
				float: right;
				opacity: 0.7;
			}

			@media screen and (max-width: 1366px){
				.profilebox{
					margin-top: 350px;
				}
			}



		.profileHeader{
			color: '#000000'; 
			font-weight: 'Bold';
			margin-top: '555px';
			width: '380px';
			 margin-right: 'auto';
			 margin-left: 'auto';
			 background: '#FF5F1F';
			 opacity: '0.9';
			 font-size: '25px';
		}

		#work{
			position: relative;
			z-index: 10;
		}

		.zoom{
			-webkit-transition: transform .1s;
  			-moz-transition: transform .1s;
  			-o-transition: transform .1s;
			transition: transform .1s;
		}

		.zoom:hover {
			transform: scale(0.95); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
		  }

		  /* Video modal */
		.responsive-container {
			position: relative;
			width: 100%;
			padding-top: 75%;
		}

		.responsive-iframe {
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			width: 100%;
			height: 100%;
			border: none;
		}

/* Kusunda Lesson Videos */
		.video-container:after {
			content: "";
			display: table;
			clear: both;
		  }

		  .listColumn{
			width:20%;
			float:left;
			padding: 20px;
			border-right: 1px solid #f0f0f0;
			height: 85%;
		  }

		  .videosColumn{
			width:80%;
			float:right;
			
		  }

		  #lessonsBtn{
			display: none;
		  }

		  #cardRight{
			float: right;
		  }

		  @media screen and (max-width: 600px) {
			.listColumn{
				display: none;
			}
			#lessonLabel{
				display: none;
			}
			.videosColumn{
				width: 100%;
			  }
			#lessonsBtn{
				display: block;
			}  
			#cardRight{
				float: left;
			}
		  }


		