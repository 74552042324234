/* Online Exhibit */


#oepic1 {
	background: url("https://www.nepalpicturelibrary.org/wp-content/uploads/2016/06/npl_2014_br01_00571.jpg");
	background-size: cover;
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-position: center;
	position: relative;
	z-index: 10;
	height: 1150px;
}

#oepic2 {
	background: url("https://archivenepal.s3.amazonaws.com/images/onlineexhibit/parijat.jpg");
	background-size: cover;
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-position: center;
	position: relative;
	z-index: 10;
	height: 1150px;
}

#oepic3 {
	background: url("https://theannapurnaexpress.com/admin/wp-content/uploads/2022/09/jhamak.jpg");
	background-size: cover;
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-position: center;
	position: relative;
	z-index: 10;
	height: 1150px;
}

#oepic4 {
	background: url("https://live.staticflickr.com/6240/6330757557_995671c07e_b.jpg");
	background-size: cover;
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-position: center;
	position: relative;
	z-index: 10;
	height: 1150px;
}

#oepic5 {
	background: url("https://live.staticflickr.com/7173/6724370717_d8dfef7954_b.jpg");
	background-size: cover;
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-position: center;
	position: relative;
	z-index: 10;
	height: 1150px;
}

#oepic6 {
	background-size: cover;
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-position: center;
	position: relative;
	z-index: 10;
	height: 1150px;
}

#oepic7 {
	background: url("https://live.staticflickr.com/65535/51114332617_f876f4a40b_b.jpg");
	background-size: cover;
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-position: center;
	position: relative;
	z-index: 10;
	height: 1150px;
}

#oepic8 {
	background: url("https://upload.wikimedia.org/wikipedia/commons/5/5e/Constitution_day_LIVE_broadcasting.jpg");
	background-size: cover;
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-position: center;
	position: relative;
	z-index: 10;
	height: 1150px;
}

#oepic9 {
	background: url("https://s3.amazonaws.com/sfc-datebook-wordpress/wp-content/uploads/sites/2/2022/10/MER3ab64977948ba84ecabff75729892_unaff1019-1536x1017.jpg");
	background-size: cover;
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-position: center;
	position: relative;
	z-index: 10;
	height: 1150px;
}

#oepic10 {
	background: url("https://static01.nyt.com/images/2023/01/31/multimedia/31Lhakpa-Sherpa-03-jzqt/31Lhakpa-Sherpa-03-jzqt-superJumbo.jpg?quality=75&auto=webp");
	background-size: cover;
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-position: center;
	position: relative;
	z-index: 10;
	height: 1150px;
}

#oepic11 {
	background: url("https://archivenepal.s3.amazonaws.com/images/onlineexhibit/dixit.jpg");
	background-size: cover;
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-position: center;
	position: relative;
	z-index: 10;
	height: 1150px;
}

#oepic12 {
	background: url("https://i.imgur.com/uXCcZSJ.jpg");
	background-size: cover;
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-position: center;
	position: relative;
	z-index: 10;
	height: 1150px;
}

#oepic13 {
	background: url("https://asiapacific.unwomen.org/sites/default/files/Field%20Office%20ESEAsia/Images/2020/02/np-Hon-Justice-Sapana-Malla-r02-960px.jpg");
	background-size: cover;
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-position: center;
	position: relative;
	z-index: 10;
	height: 1150px;
}

#oepic14 {
	background: url("https://www.himalkhabar.com/uploads/editor/himal_saptahant/ratan%20bhandari/dwarikadevi%20thakurani.jpg");
	background-size: cover;
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-position: center;
	position: relative;
	z-index: 10;
	height: 1150px;
}

#oepic15 {
	background: url("https://english.onlinekhabar.com/wp-content/uploads/2022/03/Tista-Prasai-Joshi-1024x576.jpg");
	background-size: cover;
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-position: center;
	position: relative;
	z-index: 10;
	height: 1150px;
}

@media screen and (max-width: 1366px){		
	#oepic1, #oepic2, #oepic3, #oepic4, #oepic5, #oepic6, #oepic7, #oepic8, #oepic9, #oepic10, #oepic11, #oepic12, #oepic13, #oepic14, #oepic15{
		background-attachment: scroll;
	}

	#oepic10{
		background-position: right;
	}
}

	#onlineexhibitmain {
		background: url("https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/wayne-stinson/photos/WS_CS00037.jpg");
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		background-attachment: fixed;
		height: 1150px;
	}

	@media screen and (max-width: 1366px){
		#onlineexhibitmain {
			background-attachment: scroll;
		}

	}

/* One */

#sectionone {
	background-size: cover;
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-position: center;
	position: relative;
	z-index: 10;
}

@media screen and (max-width: 1366px){		
	#sectionone{
		background-attachment: scroll;
	}
}

/* Two */

#sectiontwo {
background-size: cover;
background-repeat: no-repeat;
background-attachment: fixed;
background-position: center;
position: relative;
z-index: 10;
}

@media screen and (max-width: 1366px){		
#sectiontwo{
	background-attachment: scroll;
}
}

.info{
	text-align: left;
	color: #ffffff; 
	width: 70%;
	margin-left: auto;
	margin-right: auto;
	background: #b31b1b;
	opacity: 0.7;
	padding: 10px;
	font-weight: bold;
}

@media screen and (max-width: 1366px){		
	.info{
		width: 100%;
	}
	}

	/* Tenttom Glenn Collection */

	#onlineexhibitmainTG {
		background: url("https://archivenepal.s3.amazonaws.com/tom_glenn_collection/access/patan+street+scene-1.jpg");
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		background-attachment: fixed;
		height: 1150px;
	}

	@media screen and (max-width: 1366px){
		#onlineexhibitmainTG {
			background-attachment: scroll;
		}

	}


#oepic2TG {
	background-size: cover;
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-position: center;
	position: relative;
	z-index: 10;
	height: 1150px;
}

@media screen and (max-width: 1366px){
	#oepic2TG {
		background-attachment: scroll;
	}
}

#oepic3TG {
	background: url("https://archivenepal.s3.amazonaws.com/tom_glenn_collection/access/TGC-Carpet2.jpg");
	background-size: cover;
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-position: center;
	position: relative;
	z-index: 10;
	height: 1150px;
}
@media screen and (max-width: 1366px){
	#oepic3TG {
		background-attachment: scroll;
	}
}


#oepic4TG {
	background: url("https://archivenepal.s3.amazonaws.com/tom_glenn_collection/access/TGC-Carpet1.jpg");
	background-size: cover;
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-position: center;
	position: relative;
	z-index: 10;
	height: 1150px;
}
@media screen and (max-width: 1366px){
	#oepic4TG {
		background-attachment: scroll;
	}
}

#oepic5TG {
	background: url("https://archivenepal.s3.amazonaws.com/tom_glenn_collection/access/TGC-Tent1.jpg");
	background-size: cover;
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-position: center;
	position: relative;
	z-index: 10;
	height: 1150px;
}
@media screen and (max-width: 1366px){
	#oepic5TG {
		background-attachment: scroll;
	}
}

#oepic6TG {
	background: url("https://archivenepal.s3.amazonaws.com/tom_glenn_collection/access/TGC-Tent2.jpg");
	background-size: cover;
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-position: center;
	position: relative;
	z-index: 10;
	height: 1150px;
}
@media screen and (max-width: 1366px){
	#oepic6TG {
		background-attachment: scroll;
	}
}

#oepic7TG {
	background: url("https://archivenepal.s3.amazonaws.com/tom_glenn_collection/access/DrLarryGirijaBrilliant.jpeg");
	background-size: cover;
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-position: center;
	position: relative;
	z-index: 10;
	height: 1150px;
}
@media screen and (max-width: 1366px){
	#oepic7TG {
		background-attachment: scroll;
	}
}

#oepic8TG {
	background: url("https://archivenepal.s3.amazonaws.com/tom_glenn_collection/access/patan%2Blions%2B2%2Bkids-1.jpeg");
	background-size: cover;
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-position: center;
	position: relative;
	z-index: 10;
	height: 1150px;
}
@media screen and (max-width: 1366px){
	#oepic8TG {
		background-attachment: scroll;
	}
}

/* 1923 UK-Nepal Treaty Digibit */

#onlineexhibitmainUKNP {
	background: url("https://archivenepal.s3.amazonaws.com/images/1923-1.PNG");
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	background-attachment: fixed;
	height: 1150px;
}

@media screen and (max-width: 1366px){
	#onlineexhibitmainUKNP {
		background-attachment: scroll;
	}

}

#oepic2UKNP {
background: url("https://archivenepal.s3.amazonaws.com/images/1923-2.jpg");
background-size: cover;
background-repeat: no-repeat;
background-attachment: fixed;
background-position: center;
position: relative;
z-index: 10;
height: 1150px;
}

@media screen and (max-width: 1366px){
#oepic2UKNP {
	background-attachment: scroll;
}
}

#oepic3UKNP {
background: url("https://images.eap.bl.uk/EAP838/EAP838_1_3_1/216.jp2/full/!1400,700/0/default.jpg");
background-size: cover;
background-repeat: no-repeat;
background-attachment: fixed;
background-position: center;
position: relative;
z-index: 10;
height: 1150px;
}
@media screen and (max-width: 1366px){
#oepic3UKNP {
	background-attachment: scroll;
}
}


#oepic4UKNP {
background: url("https://archivenepal.s3.amazonaws.com/images/1923-3.PNG");
background-size: cover;
background-repeat: no-repeat;
background-attachment: fixed;
background-position: center;
position: relative;
z-index: 10;
height: 1150px;
}
@media screen and (max-width: 1366px){
#oepic4UKNP {
	background-attachment: scroll;
}
}

#oepic5UKNP {
background: url("https://images.eap.bl.uk/EAP838/EAP838_1_1_2/106.jp2/full/!1400,700/0/default.jpg");
background-size: cover;
background-repeat: no-repeat;
background-attachment: fixed;
background-position: center;
position: relative;
z-index: 10;
height: 1150px;
}
@media screen and (max-width: 1366px){
#oepic5UKNP {
	background-attachment: scroll;
}
}

#oepic6UKNP {
	background: url("https://images.eap.bl.uk/EAP838/EAP838_1_4_1/8.jp2/full/!1400,700/0/default.jpg");
	background-size: cover;
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-position: center;
	position: relative;
	z-index: 10;
	height: 1150px;
	}
	@media screen and (max-width: 1366px){
	#oepic6UKNP {
		background-attachment: scroll;
	}
	}

	#oepic7UKNP {
		background-size: cover;
		background-repeat: no-repeat;
		background-attachment: fixed;
		background-position: center;
		position: relative;
		z-index: 10;
		height: 1150px;
	}
	@media screen and (max-width: 1366px){
		#oepic7UKNP {
			background-attachment: scroll;
		}
		}
	
